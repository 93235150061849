
import Vue from "vue";
// @ts-ignore
import CardTextField from "@/components/Content/CardTextField.vue";
// @ts-ignore
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
// @ts-ignore
import CartTextArea from "@/components/Content/CardTextarea.vue";
// @ts-ignore
import CardCopy from "@/components/Content/CardCopy.vue";
// @ts-ignore
import { SegmentDataCreate, SegmentTagType } from "@/interfaces/segment";
// @ts-ignore
import { isRequired, isMaxLength } from "@/services/rule-services";
// @ts-ignore
import { isEmpty, isNull, isUndefined } from "lodash";

export default Vue.extend({
	name: "Overview",

	props: {
		is_edit: {
			type: Boolean
		},
		segment: {
			type: Object,
			default: function() {
				return {};
			}
		},
		segmentTagTypeList: {
			type: Array,
			required: true
		},
		tagGenerated: {
			type: String,
			default: ""
		}
	},

	components: { CardTextField, CardAutocomplete, CartTextArea, CardCopy },

	data: () => ({
		valid: false,
		name_rule: [],
		advertiser_rule: [],
		verb_rule: [],
		day_rule: []
	}),

	computed: {
		isEdit() {
			return this.is_edit;
		},

		getRules() {
			return {
				isRequired,
				isMaxLength
			};
		},

		getAdvertisers() {
			return this.$store.state.advertiser.advertisers_list;
		},

		getVerbUsage() {
			return this.$store.state.custom_list.verb_usage_list;
		},

		getSegmentTagType(): SegmentTagType[] {
			return this.segmentTagTypeList;
		},

		getNameButtonGenerate() {
			if (isUndefined(this.segment.id) || isNull(this.segment.id)) {
				return this.$t("segment.fields.generate-tag");
			} else {
				return this.$t("segment.fields.generate-tag-edit");
			}
		}
	},

	methods: {
		setNotification(notification: Notification) {
			return this.$store.dispatch(
				"proccess/setNotification",
				notification,
				{
					root: true
				}
			);
		},

		handleCancel() {
			this.$emit("cancel");
		},

		prepareDataCreate() {
			if (isNull(this.segment.advertiser_id)) {
				this.segment.categories = [];
			}
			return {
				id:
					isNull(this.segment.id) || isUndefined(this.segment.id)
						? undefined
						: this.segment.id,
				name: this.segment.name,
				description: isEmpty(this.segment.description)
					? "Not description"
					: this.segment.description,
				verb_usage_id: Number(this.segment.verb_usage_id),
				days: Number(this.segment.days),
				advertiser_id: isNull(this.segment.advertiser_id)
					? null
					: this.segment.advertiser_id
			} as SegmentDataCreate;
		},

		clearRules() {
			(this.name_rule = []),
				(this.advertiser_rule = []),
				(this.verb_rule = []),
				(this.day_rule = []);
		},

		async addSegmentsRules() {
			this.name_rule = [
				this.getRules.isRequired,
				this.getRules.isMaxLength
			];
			this.advertiser_rule = [];
			this.verb_rule = [this.getRules.isRequired];
			this.day_rule = [];
		},

		async validate() {
			let form = this.$refs.form;
			const valid = await form.validate();
			return await valid;
		},

		async handleSubmit(_redirect: boolean) {
			try {
				await this.addSegmentsRules();

				if (!(await this.validate())) return;

				this.$emit("submit", {
					segment: this.prepareDataCreate(),
					redirect: _redirect
				});

				this.clearRules();
			} catch (error) {
				this.clearRules();
				console.error("handleSubmit", { error: error });
			}
		},

		async handleSubmitSaveAndGeneratedTag() {
			try {
				await this.addSegmentsRules();

				if (!(await this.validate())) return;

				if (
					isNull(this.segmentTagTypeList.type) ||
					isUndefined(this.segmentTagTypeList.type)
				)
					return;

				let _id_tag = this.segmentTagTypeList.type;
				this.$emit("saveGeneratedTag", {
					segment: this.prepareDataCreate(),
					id_tag: _id_tag
				});
			} catch (error) {
				console.error("handleSubmit", { error: error });
			}
		},

		/*
		 * Metodos Fetch
		 */

		async fetchAdvertiser(e: any) {
			if (!isEmpty(this.getAdvertisers)) return;
			this.$emit("fetch-advertiser");
		},

		async fetchVerbUsage(e: any) {
			if (!isEmpty(this.getVerbUsage)) return;
			this.$emit("fetch-verb-usages");
		},

		async fetchSegmentTagType(e: any) {
			if (!isEmpty(this.getSegmentTagType)) return;
			this.$emit("fetch-tag-types");
		}
	}
});
