
import i18n from '@/plugins/i18n'
import { isEmpty } from 'lodash'
export default {
	name: "CardCopy",
	model: {
		prop: "model_data",
		event: "input",
	},
	props: {
		model_data: {},
		hint: {
			type: String,
			default: ""
		},
		reference: {
			type: String,
			default: ""
		},
		placeholder: {
			type: String,
			default: ""
		},
		label: {
			type: String,
			default: ""
		},
		required: {
			type: Boolean,
			default: false
		}
	},
	data: () => ({
		snackbar: false,
		texto: ""
	}),
	mounted() {},
	computed: {
		modelData: {
			set(val: any) {
				this.$emit("input", val);
			},
			get() {
				return this.model_data;
			},
		}
	},
	methods: {
		copyTag() {
			if(isEmpty(this.model_data)) return;
			navigator.clipboard.writeText(this.model_data)
			.then(() => {
				this.snackbar = true;
				this.texto = i18n.t("clipboard-success")
			})
			.catch(error => {
				this.snackbar = true;
				this.texto = i18n.t("clipboard-error")
			});
        },
	},
	watch: {},
};
